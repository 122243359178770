<template>
  <div class="formula-background">
    <span class="Register-here-for-up">
      <p class="text-style-1">Register here for upload</p>
      <p class="text-description">
        <span>Did you perform a procedure with VIO</span>
        <Copyright /> 3 or APC 3, that you want to share
        with the global community? Follow the link to apply for contribution
        with your video.
      </p>
      <button class="submitButton" @click="$emit('setPage', 1)">
        <span class="submission">Submission</span>
      </button>
    </span>
  </div>
</template>
<script>
import Copyright from '../../components/Symbols/Copyright.vue';
export default {
  name: "StartPage",
  components: {
    Copyright,
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

.text-description {
  padding-top: 32px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 65px;
}

.submission {
  width: 200px;
  height: 25px;
  font-family: $font_nettoLight;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.submitButton {
  width: 200px;
  text-align: center;
  justify-content: center;
  height: 57px;
  padding: 16px 0;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #00aeef;
  cursor: pointer;
}

.Register-here-for-up {
  width: 100%;
  height: 205px;
  margin: 0 auto;
  @include ts-section-headline();
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
}

.Register-here-for-up .text-style-1 {
  font-weight: bold;
  padding-top: 32px;
}

.formula-background {
  padding: 0 0 75px;
  background-color: white;
}
</style>