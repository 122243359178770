<template>
  <div class="progress-line">
    <div v-for="(page, index) in maxPages" :key="index" class="page-line">
      <div
        class="line"
        :class="[
          currentPage > index ? 'background-active' : 'background-inactive',
          index === 0 ? 'first-line' : '',
        ]"
      ></div>
      <div
        class="page-number-container"
        :class="[
          currentPage > index ? 'background-active' : 'background-inactive',
        ]"
      >
        <span class="page-number">{{ page }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProgressLine",
  data() {
    return {
      maxPages: 3,
    };
  },
  props: {
    currentPage: {
      type: Number,
      require: true,
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";
.progress-line {
  display: flex;
  height: 32px;

  .page-line {
    position: relative;
    display: flex;
    width: 33.3%;
    align-items: center;

    .line {
      height: 6px;
      width: 100%;
    }

    .first-line {
      border-radius: 3px 0 0 3px;
    }

    .page-number-container {
      position: absolute;
      right: 0;
      height: 32px;
      width: 32px;
      color: white;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .page-number {
        font-family: $font_nettoRegular;
        font-weight: 900;
        font-size: 16px;
      }
    }

    .background-active {
      background-color: #00aeef;
    }
    .background-inactive {
      background-color: #eee;
    }
  }
}
</style>