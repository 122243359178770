import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    children: [{ path: ":page" }],
  },
];

function scrollBehavior(to) {
  if (to.params.page) {
    return { selector: `#${to.params.page}` };
  }
}

const router = new VueRouter({
  routes,
  scrollBehavior,
  duplicateNavigationPolicy: "reload",
});

export default router;
