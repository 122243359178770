<template>
  <section class="VideoUploadStep1">
    <div class="card">
      <div>
        <div class="formular-input">
          <div class="personal-data-space">
            <p class="sub-title-personal-data">Personal data</p>
            <p class="text">Name*</p>
            <input
              v-model="personalData.name"
              class="input"
              placeholder="Please enter your name…"
              :class="{ required: this.personalData.name === '' }"
            />
            <p class="text">Surname*</p>
            <input
              v-model="personalData.surname"
              class="input"
              placeholder="Please enter your surname…"
              :class="{ required: this.personalData.surname === '' }"
            />
            <p class="text">Title</p>
            <input
              v-model="personalData.title"
              class="input"
              placeholder="Please enter your title…"
            />
            <p class="text">Email*</p>
            <input
              v-model="personalData.email"
              class="input"
              placeholder="Please enter your email…"
              :class="{ required: this.personalData.email === '' }"
            />
            <p class="text">Phone</p>
            <input
              v-model="personalData.phone"
              class="input"
              placeholder="Please enter your phone…"
            />
          </div>
          <div class="hospital-space">
            <p class="sub-title-hospital">Hospital</p>
            <p class="text">Institution</p>
            <input
              v-model="hospital.institution"
              class="input"
              placeholder="Please enter your institution..."
            />
            <p class="text">Department</p>
            <input
              v-model="hospital.department"
              class="input"
              placeholder="Please enter your department…"
            />
            <p class="text">Street, No.</p>
            <input
              v-model="hospital.street"
              class="input"
              placeholder="Please enter your street and no…"
            />
            <div class="text">ZIP</div>
            <input
              v-model="hospital.zip"
              class="input"
              placeholder="Please enter your ZIP…"
            />

            <p class="text">City</p>
            <input
              v-model="hospital.city"
              class="input"
              placeholder="Please enter your city…"
            />

            <p class="text">Country</p>
            <input
              v-model="hospital.country"
              class="input"
              placeholder="Please enter your country…"
            />
          </div>
        </div>
        <div class="buttons">
          <button class="return-button" @click="$emit('setPage', 0)">
            <i id="arrow" class="fas fa-arrow-left fa-2x"></i>
          </button>
          <button class="forward-button" @click="handleNextButtonClick()">
            <i id="arrow" class="fas fa-arrow-right fa-2x"></i>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "VideoUploadStep1",
  data() {
    return {
      personalData: {
        name: undefined,
        surname: undefined,
        title: undefined,
        email: undefined,
        phone: undefined,
      },
      hospital: {
        institution: undefined,
        department: undefined,
        street: undefined,
        zip: undefined,
        city: undefined,
        country: undefined,
      },
    };
  },
  computed: {
    requiredFields() {
      return {
        name: this.personalData.name,
        surname: this.personalData.surname,
        email: this.personalData.email,
      };
    },
  },
  methods: {
    requiredFieldsAreSet() {
      return (
        this.requiredFields.name &&
        this.requiredFields.surname &&
        this.requiredFields.email
      );
    },
    setFormularDataToStore() {
      this.$store.commit("setPersonalData", this.personalData);
      this.$store.commit("setHospital", this.hospital);
    },
    navigateToNextStep() {
      this.$emit("setPage", 2);
    },
    triggerRequiredStates() {
      for (var key in this.requiredFields) {
        if (this.requiredFields[key] === undefined) {
          this.personalData[key] = "";
        }
      }
    },
    handleNextButtonClick() {
      if (this.requiredFieldsAreSet()) {
        this.setFormularDataToStore();
        this.navigateToNextStep();
      } else {
        this.triggerRequiredStates();
      }
    },
  },
  mounted() {
    this.personalData = this.$store.state.uploadFormular.personalData;
    this.hospital = this.$store.state.uploadFormular.hospital;
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

input {
  font-family: $font_nettoLight;
}

.personal-data-space {
  width: 48%;

  @include breakpoint(xs) {
    width: unset;
  }
}

.hospital-space {
  width: 48%;

  @include breakpoint(xs) {
    width: unset;
  }
}

.formular-input {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.text {
  font-family: $font_nettoRegular;
  height: 12px;
  margin: 0 15px 5px 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.input {
  width: 100%;
  height: 36px;
  margin: 8px 0 0;
  padding: 8px 0 8px 17px;
  border-radius: 8px;
  border: solid 1px #999;
  margin-bottom: 24px;
}

.input::placeholder {
  color: #999;
  width: 563px;
  height: 20px;
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.sub-title-personal-data {
  font-family: $font_nettoRegular;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 300;
  color: #231f20;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  @include breakpoint(xs) {
    margin-bottom: 20px;
  }
}

.sub-title-hospital {
  font-family: $font_nettoRegular;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 300;
  color: #231f20;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  @include breakpoint(xs) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

.return-button {
  cursor: pointer;
  width: 80px;
  height: 57px;
  padding: 16px 27px 16px 28px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #00aeef;
}

.forward-button {
  cursor: pointer;
  width: 80px;
  height: 57px;
  padding: 16px 27px 16px 28px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #00aeef;
}

#arrow {
  color: white;
}

.hospital {
  width: 580px;
  height: 34px;
  margin: 0 0 44px 40px;
  @include ts-section-headline();
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
}

.personal-data {
  width: 580px;
  height: 34px;
  margin: 0 40px 44px 0;
  @include ts-section-headline();
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
}

.required {
  border: solid 2px red;
}
</style>