import Vue from "vue";

export const createChartsModule = () => {
  return {
    state: {
      scatterChartData: [],
      scatterChartLoading: true,
      chartVideos: [],
    },
    actions: {
      async loadChartData({ commit }, parameter) {
        commit("setScatterChartLoading", true);

        await Vue.axios.post('matrix', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode }).then(result => {
          commit('setScatterChartData', result.data);
        }).catch(error => {
          throw new Error(`API ${error}`);
        }).finally(() => {
          commit("setScatterChartLoading", false);
        });
      },
      async loadScatterGraphVideos({ commit }, parameter) {
        await Vue.axios.post('matrix/videos', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode, "SubMode": parameter.submode, "Effect": parameter.effect }).then(result => {
          var videosWithModes = result.data.map(v => ({ ...v, ParentMode: parameter.mode }));
          commit("setChartVideos", videosWithModes);
        }).catch(error => {
          throw new Error(`API ${error}`);
        })
      },
      async loadBarChartVideos({ commit }, parameter) {
        await Vue.axios.post('endocut/videos', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode, "Effect": parameter.effect, "Duration": parameter.duration, "Interval": parameter.interval }).then(result => {
          var videosWithModes = result.data.map(v => ({ ...v, ParentMode: parameter.mode }));
          commit("setChartVideos", videosWithModes);
        }).catch(error => {
          throw new Error(`API ${error}`);
        })
      },
      async loadBarChartTopVideos({ commit }, parameter) {
        var topVideoData = [];
        await Vue.axios.post('endocut/top', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode }).then(result => {
          topVideoData = result.data;
        }).catch(error => {
          throw new Error(`API ${error}`);
        });

        var topBarChartVideos = [];

        for (const videoData of topVideoData) {
          await Vue.axios.post('endocut/videos', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode, "Effect": videoData.E, "Duration": videoData.D, "Interval": videoData.I }).then(result => {
            topBarChartVideos = topBarChartVideos.concat(result.data)
          }).catch(error => {
            throw new Error(`API ${error}`);
          })
        }

        var videosWithModes = topBarChartVideos.map(v => ({ ...v, ParentMode: parameter.mode }));
        commit("setChartVideos", videosWithModes);
      }
    },
    mutations: {
      setScatterChartData(state, chartData) {
        state.scatterChartData = chartData;
      },
      setScatterChartLoading(state, loading) {
        state.scatterChartLoading = loading;
      },
      setChartVideos(state, chartVideos) {
        state.chartVideos = chartVideos;
      },
      removeChartVideos(state) {
        state.chartVideos = [];
      },
    }
  }
}