import Vue from "vue";

export const createDisciplineFilterModule = () => {
  return {
    state: {
      selectedDiscipline: "",
      selectedIntervention: "",
      disciplineOptions: [],
      interventionOptions: [],
    },
    actions: {
      loadDisciplineOptions({ commit }) {
        Vue.axios.post('disciplines').then(result => {
          commit('setDisciplineOptions', result.data);
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
      },
      async getfilteredInterventions({ commit, state }) {
        var allInterventions;
        await Vue.axios.post('interventions', { "Discipline": state.selectedDiscipline }).then(result => {
          allInterventions = result.data[0].Interventions;
        }).catch(error => {
          throw new Error(`API ${error}`);
        });

        var filteredInterventions = [];

        allInterventions.forEach(intervention => {
          Vue.axios.post('modes', { "Discipline": state.selectedDiscipline, "Intervention": intervention }).then(result => {
            result.data.forEach(mode => {
              if (mode.VioCount != 0 && filteredInterventions.indexOf(intervention) < 0) {
                filteredInterventions.push(intervention);
              }
            })
          })
        });

        commit('setInterventionOptions', filteredInterventions);
      }
    },
    mutations: {
      setDisciplineOptions(state, disciplines) {
        state.disciplineOptions = disciplines;
      },
      setInterventionOptions(state, interventions) {
        if (interventions.length === 0) {
          state.interventionOptions = [];
          state.selectedIntervention = "";
        }

        state.interventionOptions = interventions;
      },
      setSelectedDiscipline(state, discipline) {
        state.selectedDiscipline = discipline
      },
      setSelectedIntervention(state, intervention) {
        state.selectedIntervention = intervention
      }
    }
  }
}