<template>
  <section class="video-upload-step2">
    <div class="sub-title">Measurement data</div>
    <div class="formular-input">
      <div class="sec1">
        <p class="text1">Discipline *</p>
        <select :class="{ required: this.measurementData.discipline === '' }" v-model="measurementData.discipline"
          class="first-input">
          <option :value="undefined" disabled>Choose Discipline</option>
          <option value="Gastroenterology">Gastroenterology</option>
          <option value="Pulmonology">Pulmonology</option>
          <option value="Surgery">Surgery</option>
          <option value="Gynecology">Gynecology</option>
          <option value="Urology">Urology</option>
        </select>
        <p class="text1">Name of procedure *</p>
        <input :class="{ required: this.measurementData.procedureName === '' }" v-model="measurementData.procedureName"
          class="first-input" placeholder="Please enter the name of the procedure..." />
        <p class="text1">Description of the procedure</p>
        <textarea v-model="measurementData.procedureDescription" class="writeable"
          placeholder="Please enter your description…"></textarea>
        <p class="text1">COAG mode *</p>
        <select :class="{ required: this.measurementData.coagMode === '' }" v-model="measurementData.coagMode"
          class="first-input">
          <option :value="undefined" disabled>Choose COAG mode</option>
          <option value="preciseSECT">preciseSECT</option>
          <option value="softCOAG">softCOAG</option>
          <option value="forcedCOAG">forcedCOAG</option>
          <option value="swiftCOAG">swiftCOAG</option>
          <option value="twinCOAG">twinCOAG</option>
          <option value="sprayCOAG">sprayCOAG</option>
          <option value="thermoSEAL">thermoSEAL</option>
          <option value="biCLAMP">biCLAMP</option>
          <option value="softCOAG bipolar">softCOAG bipolar</option>
          <option value="forcedCOAG bipolar">forcedCOAG bipolar</option>
          <option value="no COAG-mode was used">no COAG-mode was used</option>
        </select>
        <p class="text1">Which COAG effect setting did you use?</p>
        <input v-model="measurementData.coagEffectSettings" class="first-input"
          placeholder="Please enter your settings..." />
      </div>
      <div class="sec2">
        <p class="text1">CUT mode *</p>

        <select :class="{ required: this.measurementData.cutMode === '' }" v-model="measurementData.cutMode"
          class="first-input">
          <option :value="undefined" disabled>Choose CUT mode</option>
          <option value="autoCUT">autoCUT</option>
          <option value="dryCUT">dryCUT</option>
          <option value="highCUT">highCUT</option>
          <option value="endoCUT Q">endoCUT Q</option>
          <option value="endoCUT I">endoCUT I</option>
          <option value="highCUT bipolar">highCUT bipolar</option>
          <option value="autoCUT bipolar">autoCUT bipolar</option>
          <option value="no CUT-mode was used">no CUT-mode was used</option>
        </select>
        <div v-if="!isSpecialCutMode()">
          <p class="text1">Which CUT effect settings did you use?</p>
          <input v-model="measurementData.cutEffectSettings" class="first-input"
            placeholder="Please enter your settings..." />
        </div>
        <div v-if="isSpecialCutMode()">
          <p class="text1">Effect</p>
          <input v-model="measurementData.cutEffect" class="first-input" placeholder="Effect" />
          <p class="text1">Duration</p>
          <input v-model="measurementData.cutDuration" class="first-input" placeholder="Duration" />
          <p class="text1">Interval</p>
          <input v-model="measurementData.cutInterval" class="first-input" placeholder="Interval" />
        </div>
        <p class="text1">APC mode</p>

        <select v-model="measurementData.apcMode" class="first-input">
          <option :value="undefined" disabled>Choose APC mode</option>
          <option value="preciseAPC">preciseAPC</option>
          <option value="forcedAPC">forcedAPC</option>
          <option value="pulsedAPC fast">pulsedAPC fast</option>
          <option value="pulsedAPC slow">pulsedAPC slow</option>
          <option value=">no APC was used">no APC was used</option>
        </select>
        <p class="text1">Which APC effect settings did you use?</p>
        <input v-model="measurementData.apcEffectSettings" class="first-input"
          placeholder="Please enter your settings..." />
        <p class="text1">Which instrument did you use? *</p>
        <input :class="{ required: this.measurementData.instrument === '' }" v-model="measurementData.instrument"
          class="first-input" placeholder="Please enter your instrument..." />
      </div>
    </div>
    <div class="buttons">
      <button class="return-button" @click="$emit('setPage', 1)">
        <i id="arrow" class="fas fa-arrow-left fa-2x"></i>
      </button>
      <button class="forward-button" @click="handleNextButtonClick()">
        <i id="arrow" class="fas fa-arrow-right fa-2x"></i>
      </button>
    </div>
  </section>
</template>
<script>
import * as modes from "../../contstants/modes";

export default {
  name: "VideoUploadStep2",
  data() {
    return {
      measurementData: {
        discipline: undefined,
        procedureName: undefined,
        procedureDescription: undefined,
        coagMode: undefined,
        coagEffectSettings: undefined,
        cutMode: undefined,
        cutEffectSettings: undefined,
        cutEffect: undefined,
        cutDuration: undefined,
        cutInterval: undefined,
        apcMode: undefined,
        apcEffectSettings: undefined,
        instrument: undefined,
      },
    };
  },
  computed: {
    requiredFields() {
      return {
        discipline: this.measurementData.discipline,
        procedureName: this.measurementData.procedureName,
        coagMode: this.measurementData.coagMode,
        cutMode: this.measurementData.cutMode,
        instrument: this.measurementData.instrument,
      };
    },
  },
  methods: {
    requiredFieldsAreSet() {
      return (
        this.requiredFields.discipline &&
        this.requiredFields.procedureName &&
        this.requiredFields.coagMode &&
        this.requiredFields.cutMode &&
        this.requiredFields.instrument
      );
    },
    isSpecialCutMode() {
      return (
        this.measurementData.cutMode == modes.ENDOCUT_Q ||
        this.measurementData.cutMode == modes.ENDOCUT_I
      );
    },
    navigateToNextStep() {
      this.$emit("setPage", 3);
    },
    setFormularDataToStore() {
      this.$store.commit("setMeasurementData", this.measurementData);
    },
    checkForCutModeInputs() {
      if (this.isSpecialCutMode()) {
        this.measurementData.cutEffectSettings = undefined;
      } else {
        this.measurementData.cutEffect = undefined;
        this.measurementData.cutDuration = undefined;
        this.measurementData.cutInterval = undefined;
      }
    },
    triggerRequiredStates() {
      for (var key in this.requiredFields) {
        if (this.requiredFields[key] === undefined) {
          this.measurementData[key] = "";
        }
      }
    },
    handleNextButtonClick() {
      if (this.requiredFieldsAreSet()) {
        this.checkForCutModeInputs();
        this.setFormularDataToStore();
        this.navigateToNextStep();
      } else {
        this.triggerRequiredStates();
      }
    },
  },
  mounted() {
    this.measurementData = this.$store.state.uploadFormular.measurementData;
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

input {
  font-family: $font_nettoLight;
}

option {
  font-family: $font_nettoLight;
  font-size: 16px;
}

textarea {
  font-family: $font_nettoRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.sec1 {
  width: 48%;

  @include breakpoint(xs) {
    width: unset;
  }
}

.sec2 {
  width: 48%;

  @include breakpoint(xs) {
    width: unset;
  }
}

.formular-input {
  display: flex;
  justify-content: space-between;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.checkbox {
  width: 16px;
  height: 16px;
}

.first-input::placeholder {
  color: #999;
  height: 20px;
  font-family: $font_nettoRegular;
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 25px;
}

.writeable {
  margin: 8px 0 0;
  padding: 8px 0 96px 17px;
  border-radius: 8px;
  border: solid 1px #999;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 96.5%;
  width: 100%;
}

.text1 {
  font-family: $font_nettoRegular;
  margin-top: 15px;
  height: 12px;
  margin: 0 15px 5px 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.first-input {
  width: 100%;
  height: 36px;
  margin: 8px 0 0;
  padding: 6px 0 6px 17px;
  border-radius: 8px;
  border: solid 1px #999;
  margin-bottom: 20px;
}

.sub-title {
  height: 34px;
  margin: 0 0 44px;
  @include ts-section-headline();
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
}

.return-button {
  cursor: pointer;
  width: 80px;
  height: 57px;
  padding: 16px 27px 16px 28px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #00aeef;
}

.forward-button {
  cursor: pointer;
  width: 80px;
  height: 57px;
  padding: 16px 27px 16px 28px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #00aeef;
}

#arrow {
  color: white;
}

.required {
  border: solid 2px red;
}
</style>