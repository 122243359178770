<template>
  <section class="upload-formular" id="upload-formular">
    <p class="title">Submit a Video</p>
    <ProgressLine class="progress-line" :currentPage="page" v-if="pageIsFormularStep()" />
    <StartPage @setPage="(newPage) => setPage(newPage)" v-if="page == 0" />
    <VideoUploadStep1 @setPage="(newPage) => setPage(newPage)" v-if="page == 1" />
    <VideoUploadStep2 @setPage="(newPage) => setPage(newPage)" v-if="page == 2" />
    <VideoUploadStep3 @setPage="(newPage) => setPage(newPage)" v-if="page == 3" />
    <ThankyouPage @setPage="(newPage) => setPage(newPage)" v-if="page == 4" />
  </section>
</template>
<script>
import VideoUploadStep1 from "./VideoUploadStep1.vue";
import VideoUploadStep2 from "./VideoUploadStep2.vue";
import VideoUploadStep3 from "./VideoUploadStep3.vue";
import StartPage from "./StartPage.vue";
import ThankyouPage from "./ThankyouPage.vue";
import ProgressLine from "./ProgressLine.vue";

export default {
  name: "UploadFormular",
  components: {
    StartPage,
    VideoUploadStep1,
    VideoUploadStep2,
    VideoUploadStep3,
    ThankyouPage,
    ProgressLine,
  },
  data() {
    return {
      page: 0,
    };
  },
  methods: {
    setPage(newPage) {
      this.page = newPage;
    },
    pageIsFormularStep() {
      return this.page !== 0 && this.page !== 4;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

.upload-formular {
  width: 65%;
  max-width: 1200px;
  margin: 0 auto;

  .progress-line {
    margin-bottom: 45px;
  }
}

.title {
  @include ts-section-headline();
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #231f20;
  padding-top: 52px;
  margin-bottom: 45px;
}
</style>