import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import { createDisciplineFilterModule } from './modules/disciplineFilter';
import { createModusButtonModule } from './modules/modusButton';
import { createChartsModule } from './modules/charts';
import { createPlaylistModule } from "./modules/playlist";
import { createMapsModule } from "./modules/maps";
import { createUploadFormularModule } from "./modules/uploadFormular";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = "https://dev.viovideo.erbe-med.com/backend/";

export default new Vuex.Store({
  modules: {
    disciplineFilter: createDisciplineFilterModule(),
    modusButton: createModusButtonModule(),
    charts: createChartsModule(),
    playlist: createPlaylistModule(),
    maps: createMapsModule(),
    uploadFormular: createUploadFormularModule(),
  }
});