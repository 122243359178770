<template>
  <section class="video-upload-step-3">
    <div class="card">
      <div class="loading-state" v-if="loading">
        <p class="title">Uploading Video</p>
        <div class="loading-spinner"></div>
      </div>
      <div v-if="!loading" class="input-form">
        <p class="title">Video file upload</p>
        <p>Upload your video (MP4, AVI, Quicktime)</p>
        <input @change="onUpload" hidden class="video-upload-input" id="video-upload-input" type="file" />
        <label class="video-upload-input-button" for="video-upload-input">
          <span class="video-upload-input-button-text">{{ video ? video.name : 'Choose File' }}</span>
          <i class="fas fa-upload"></i>
        </label>
        <p>Privacy Policy and Terms of use *</p>
        <div class="checkbox-wrapper">
          <input v-model="legal.privacyPolicyTermsOfUse" class="video-upload-input-checkbox" type="checkbox" />
          <label class="checkbox-label">
            Yes i have read the
            <a href="https://de.erbe-med.com/de-en/meta-navigation/privacy-policy/" target="_blank">
              privacy policy
            </a>
            and accept the
            <a href="https://erbe-med.com/fileadmin/pdf/company/Terms_of_use_EN.pdf" target="_blank">
              terms of use
            </a>
          </label>
        </div>
        <p>The copyright license agreement *</p>
        <div class="checkbox-wrapper">
          <input v-model="legal.copyRight" class="video-upload-input-checkbox" type="checkbox" />
          <label class="checkbox-label">
            I have read and understood the
            <a href="Copyright License Agreement External to Erbe.pdf" download>
              copyright license agreement
            </a>
            . I agree to grant Erbe the right to proceed with the uploaded video
            material as described in the document.
          </label>
        </div>
        <p>Do you want us to get in touch with you?</p>
        <div class="checkbox-wrapper">
          <input v-model="legal.promotion" class="video-upload-input-checkbox" type="checkbox" />
          <label class="checkbox-label">
            Yes, I would like to be informed about Erbe products, promotions and
            events.
          </label>
        </div>
      </div>
      <div v-if="!loading" class="buttons">
        <button class="return-button" @click="$emit('setPage', 2)">
          <i id="arrow" class="fas fa-arrow-left fa-2x"></i>
        </button>
        <button class="submit-button" @click="handleSubmitButtonClick()">
          <span class="submit">Submit</span>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { uploadVideoToFirebase, callSendMailFunction } from "../../services/firebaseService";
export default {
  name: "VideoUploadStep3",
  data() {
    return {
      legal: {
        privacyPolicyTermsOfUse: false,
        copyRight: false,
        promotion: false,
      },
      video: null,
      loading: false,
    };
  },
  computed: {
    requiredFields() {
      return {
        privacyPolicyTermsOfUse: this.legal.privacyPolicyTermsOfUse,
        copyRight: this.legal.copyRight,
        video: this.video,
      };
    },
    formularDataState() {
      return this.$store.state.uploadFormular;
    },
  },
  methods: {
    requiredFieldsAreSet() {
      return (
        this.requiredFields.privacyPolicyTermsOfUse &&
        this.requiredFields.copyRight &&
        this.requiredFields.video
      );
    },
    setFormularDataToStore() {
      this.$store.commit("setLegal", this.legal);
    },
    navigateToNextStep() {
      this.$emit("setPage", 4);
    },
    formatFormularData() {
      const data = {
        formularData: {},
      };
      data.formularData.personalData = this.formularDataState.personalData;
      data.formularData.hospital = this.formularDataState.hospital;
      data.formularData.measurementData =
        this.formularDataState.measurementData;
      data.formularData.legal = this.formularDataState.legal;
      data.downloadURL = this.formularDataState.downloadURL;

      return data;
    },
    async handleSubmitButtonClick() {
      if (this.requiredFieldsAreSet() && !this.loading) {
        this.loading = true;
        this.setFormularDataToStore();
        await uploadVideoToFirebase(this.video);
        callSendMailFunction(this.formatFormularData());
        this.$store.commit("resetFormularDataState");
        this.loading = false;
        this.navigateToNextStep();
      }
    },
    onUpload(event) {
      this.video = event.target.files[0];
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss";

.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, .3);
  border-radius: 50%;
  border-top-color: black;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.title {
  text-align: center;
  font-size: 28px;
  font-weight: 100;
  margin-bottom: 35px;
}

.video-upload-step-3 {
  font-family: $font_nettoRegular;

  .card {
    .input-form {
      width: 50%;
      margin: 0 auto;

      @include breakpoint(xs) {
        width: unset;
      }

      @include breakpoint(s) {
        width: unset;
      }

      .video-upload-input-checkbox {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        display: inline-flex;
        position: relative;
        width: 16px;
        height: 16px;
        border: 1px solid black;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .video-upload-input-checkbox::before {
        content: "";
        width: 10px;
        height: 10px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: #00aeef;
        border-radius: 3px;
      }

      .video-upload-input-checkbox:checked::before {
        transform: scale(1);
      }

      .video-upload-input {
        &-button {
          display: flex;
          cursor: pointer;
          border: 1px solid black;
          border-radius: 6px;
          padding: 6px 15px;
          max-width: 800px;
          justify-content: space-between;

          &-text {
            color: #999;
          }
        }
      }

      .checkbox-wrapper {
        display: grid;
        grid-template-columns: 16px 1fr;

        .checkbox-label {
          margin-left: 10px;
          color: #999;
        }
      }
    }

    .buttons {
      display: flex;
      margin-top: 48px;
      justify-content: space-between;
      margin-bottom: 40px;

      .return-button {
        cursor: pointer;
        width: 80px;
        height: 57px;
        padding: 16px 27px 16px 28px;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: #00aeef;

        #arrow {
          color: white;
        }
      }

      .submit-button {
        cursor: pointer;
        width: 200px;
        height: 57px;
        padding: 16px 27px 16px 28px;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: #00aeef;

        @include breakpoint(xs) {
          width: 120px;
        }

        .submit {
          width: 200px;
          height: 25px;
          font-family: $font_nettoLight;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
        }
      }
    }
  }
}

p {
  margin-bottom: 10px;
  margin-top: 30px;
}

a {
  color: #00aeef;
}
</style>