<template>
  <section id="multimedia" class="multimedia">
    <img class="multimedia-image" src="~@/assets/images/Multimedia.jpg" />
    <div class="multimedia-text">
      <h2 class="multimedia-text-headline">Further channels and media</h2>
    </div>
    <div class="multimedia-grid">
      <multimedia-grid-item v-for="item in multimediaItems" :key="item.key" :id="item.id" :headline="item.headline"
        :subheadline="item.subheadline" :src="item.icon" :href="item.href" :backgroundColor="item.bgColor">
      </multimedia-grid-item>
    </div>
  </section>
</template>

<script>
import MultimediaGridItem from "@/components/MultimediaGridItem.vue";
export default {
  name: "MultimediaSection",
  components: {
    MultimediaGridItem,
  },
  props: {
    backgroundColor: String,
  },
  data() {
    return {
      multimediaItems: [
        {
          headline: "Our workshops and trainings for physicians",
          subheadline: "EDUCATION",
          icon: "/svg/multimedia-icon-learning.svg",
          href: "https://en.erbe-med.com/en-en/academyportal/physicians/all-disciplines/",
          bgColor: "rgb(190, 215, 71)",
        },
        {
          headline: "Gastroenterology",
          subheadline: "MEDICAL SPECIALTY WEBPAGE",
          icon: "/svg/multimedia-icon-erbe.svg",
          href: "https://en.erbe-med.com/en-en/medical-specialties/gastroenterology/",
          bgColor: "rgb(139, 199, 81)",
        },
        {
          headline: "Hybrid technology in flexible endoscopy",
          subheadline: "HYBRID TECHNOLOGY IN FLEXIBLE ENDOSCOPY",
          icon: "/svg/multimedia-icon-erbe.svg",
          href: "https://hybrid.erbe-med.com/",
          bgColor: "rgb(0, 175, 173)",
        },
        {
          headline: "Greater convenience and safety with <span>FiAPC</span><sup class='copyrightSup'>®</sup> probes",
          subheadline: "<span>FiAPC</span><sup class='copyrightSup'>®</sup>",
          icon: "/svg/multimedia-icon-erbe.svg",
          href: "https://fiapc.erbe-med.com/#/en/",
          bgColor: "#00ae9d",
        },
        {
          headline: "Intelligent submucosal dissection in ESD and POEM",
          subheadline: "PRECISESECT AND HYBRIDKNIFE",
          icon: "/svg/multimedia-icon-erbe.svg",
          href: "https://en.erbe-med.com/en-en/medical-specialties/gastroenterology/precisesect/",
          bgColor: "rgb(190, 215, 71)",
        },
        {
          headline: "Medical Insights ePapers, list of publications",
          subheadline: "PUBLICATIONS",
          icon: "/svg/multimedia-icon-publication.svg",
          href: "https://de.erbe-med.com/de-en/education/publications/",
          bgColor: "#00ae9d",
        },
        {
          headline: "Medical Insights Podcasts",
          subheadline: "PODCAST",
          icon: "/svg/multimedia-icon-podcast.svg",
          href: "https://podcastb0e4aa.podigee.io/",
          bgColor: "#00ac6c",
        },
      ],
    };
  },
  computed: {
    bgColor() {
      return {
        "background-color": this.backgroundColor,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.multimedia {
  height: auto;
  position: relative;
  overflow: hidden;

  &-image {
    height: auto;
    width: 100%;
    min-width: 1920px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(xs) {
      min-width: 1440px;
      top: 50%;
    }

    @include breakpoint(s) {
      min-width: 1440px;
      top: 50%;
    }
  }

  &-text {
    @include padding-h(60px);
    @include padding-v(60px);
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;

    &-headline {
      @include ts-section-headline();
      color: $text_dark;
      margin-bottom: 20px;
      text-shadow: 0px 0px 3px #ffffff;

      @include breakpoint(s) {
        margin-bottom: 15px;
      }

      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }

    &-subheadline {
      @include ts-section-subheadline();
      color: $text_dark;
    }
  }

  &-grid {
    @include padding-h(60px);
    margin-bottom: 4rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;

    @include breakpoint(s) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xs) {
      grid-template-columns: 1fr;
    }
  }
}
</style>