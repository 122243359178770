import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { ref as storageRef, getDownloadURL, uploadBytesResumable, getStorage, connectStorageEmulator } from "firebase/storage";
import store from "../store/index";

var app;

const firebaseConfig = {
  apiKey: "AIzaSyDbySuFloKS375dJ6CKYxjZCH3GZef75QE",
  authDomain: "vio-on-video-a9302.firebaseapp.com",
  projectId: "vio-on-video-a9302",
  storageBucket: "vio-on-video-a9302.appspot.com",
  messagingSenderId: "1049655202395",
  appId: "1:1049655202395:web:84e51bc94332a6e3da657e",
};


function initializeFirebase() {
  app = initializeApp(firebaseConfig);
}

function callSendMailFunction(formularData) {
  const functions = getFunctions(app);
  if (process.env.NODE_ENV == "development") {
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }
  const sendMailToErbe = httpsCallable(functions, "sendMailToErbe");
  sendMailToErbe(formularData);
}

async function uploadVideoToFirebase(file) {
  const storage = getStorage(app);
  if (process.env.NODE_ENV == "development") {
    connectStorageEmulator(storage, '127.0.0.1', 9199);
  }
  const onUploadRef = storageRef(storage, file.name + '-' + Date.now());
  await uploadBytesResumable(onUploadRef, file);
  const downloadURL = await getDownloadURL(onUploadRef);
  store.commit("setDownloadURL", downloadURL);
}

export { initializeFirebase, uploadVideoToFirebase, callSendMailFunction }