import Vue from "vue";
import VueAgile from "vue-agile";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  initializeAnalytics
} from "@/services/analytics";
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as VueGoogleMaps from 'vue2-google-maps'
import { initializeFirebase } from "./services/firebaseService";

Vue.config.productionTip = false;
Vue.use(VueAgile);
Vue.use(VueAxios, axios);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC4ckKqC_Ykimo4r2KO9WfHEs0LNaK1AM0",
  },
})

initializeFirebase();
initializeAnalytics();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");