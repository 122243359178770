import Vue from "vue";

export const createMapsModule = () => {
  return {
    state: {
      marker: [],
      videos: [],
    },
    actions: {
      async loadMarker({ commit }, parameter) {

        await Vue.axios.post('map', { "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode }).then(result => {
          var markerData = result.data;

          markerData.forEach(country => {
            country.Cities.forEach(marker => {
              var mappedMarker = mapApiMarker(marker);
              commit("addMaker", mappedMarker);
            });
          });
        }).catch(error => {
          throw new Error(`API ${error}`);
        })
      },
      async loadMapVideos({ commit }, parameter) {

        await Vue.axios.post('map/videos', { "City": parameter.city, "Country": parameter.country, "Discipline": parameter.discipline, "Intervention": parameter.intervention, "Mode": parameter.mode }).then(result => {
          commit("addMapVideos", result.data);
        }).catch(error => {
          throw new Error(`API ${error}`);
        })
      }
    },
    mutations: {
      addMaker(state, marker) {
        state.marker.push(marker)
      },
      resetMarker(state) {
        state.marker = [];
      },
      addMapVideos(state, videos) {
        state.videos = videos;
      },
      resetMapVideos(state) {
        state.videos = [];
      }
    }
  }
}

function mapApiMarker(marker) {
  var mappedMarker = {
    lat: Number(marker.Lat),
    lng: Number(marker.Long),
    city: marker.City,
    country: marker.Country,
  }
  return mappedMarker;
}