export const createUploadFormularModule = () => {
  return {
    state: {
      personalData: {
        name: undefined,
        surname: undefined,
        title: undefined,
        email: undefined,
        phone: undefined,
      },
      hospital: {
        institution: undefined,
        department: undefined,
        street: undefined,
        zip: undefined,
        city: undefined,
        country: undefined,
      },
      measurementData: {
        discipline: undefined,
        procedureName: undefined,
        procedureDescription: undefined,
        coagMode: undefined,
        coagEffectSettings: undefined,
        cutMode: undefined,
        cutEffectSettings: undefined,
        cutEffect: undefined,
        cutDuration: undefined,
        cutInterval: undefined,
        apcMode: undefined,
        apcEffectSettings: undefined,
        instrument: undefined,
      },
      legal: {
        privacyPolicyTermsOfUse: false,
        copyRight: false,
        promotion: false,
      },
      downloadURL: "",
    },
    mutations: {
      setPersonalData(state, personalData) {
        state.personalData = personalData;
      },
      setHospital(state, hospital) {
        state.hospital = hospital;
      },
      setMeasurementData(state, measurementData) {
        state.measurementData = measurementData;
      },
      setLegal(state, legal) {
        state.legal = legal;
      },
      setDownloadURL(state, downloadURL) {
        state.downloadURL = downloadURL;
      },
      resetFormularDataState(state) {
        state.personalData = {
          name: undefined,
          surname: undefined,
          title: undefined,
          email: undefined,
          phone: undefined,
        }
        state.hospital = {
          institution: undefined,
          department: undefined,
          street: undefined,
          zip: undefined,
          city: undefined,
          country: undefined,
        }
        state.measurementData = {
          discipline: undefined,
          procedureName: undefined,
          procedureDescription: undefined,
          coagMode: undefined,
          coagEffectSettings: undefined,
          cutMode: undefined,
          cutEffectSettings: undefined,
          cutEffect: undefined,
          cutDuration: undefined,
          cutInterval: undefined,
          apcMode: undefined,
          apcEffectSettings: undefined,
          instrument: undefined,
        }
        state.legal = {
          privacyPolicyTermsOfUse: false,
          copyRight: false,
          promotion: false,
        }
        state.downloadURL = ""
      }
    }
  }
}