export const createPlaylistModule = () => {
  return {
    state: {
      videos: [],
    },
    mutations: {
      loadPlaylistVideos(state) {
        state.videos = JSON.parse(localStorage.getItem("playlist")) ?? [];
      },
      handleAddPlaylistButtonClick(state, video) {
        var indexOfStateVideo = -1;

        state.videos.map((playlistVideos, index) => {
          if (playlistVideos.VideoId === video.VideoId) {
            indexOfStateVideo = index;
          }
        })

        if (indexOfStateVideo === -1) {
          state.videos.push(video)
        } else {
          state.videos.splice(indexOfStateVideo, 1);
        }

        localStorage.setItem("playlist", JSON.stringify(state.videos));
      },
      clearPlaylist(state) {
        state.videos = [];

        localStorage.setItem("playlist", JSON.stringify(state.videos));
      }
    }
  }
}