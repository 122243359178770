import Vue from "vue";

export const createModusButtonModule = () => {
  return {
    state: {
      modes: [],
      selectedMode: "",
      selectedModeVideoCount: 0
    },
    actions: {
      async loadmodusButtons({ commit }, parameter) {
        await Vue.axios.post('modes', { "Discipline": parameter.discipline, "Intervention": parameter.intervention }).then(async function (modes) {
          var splittedModes = await splitEndocutButton(modes.data, parameter.discipline, parameter.intervention);
          commit('setModes', splittedModes);
        }).catch(error => {
          throw new Error(`API ${error}`);
        });
      },
      checkForVideos({ commit, state }) {
        state.modes.forEach(mode => {
          if (mode.Mode === state.selectedMode) {
            commit('setSelectedModeVideoCount', mode.VioCount)
          }
        });
      }
    },
    mutations: {
      setModes(state, modes) {
        state.modes = modes;
      },
      setSelectedMode(state, mode) {
        state.selectedMode = mode;
      },
      setSelectedModeVideoCount(state, count) {
        state.selectedModeVideoCount = count;
      }
    }
  }
}

async function splitEndocutButton(modes, discipline, intervention) {
  removeEndocut(modes);

  var newModes = [{ "Mode": "endoCUT Q", "VioCount": await getVioCountForMode("endoCUT Q", discipline, intervention) }, { "Mode": "endoCUT I", "VioCount": await getVioCountForMode("endoCUT I", discipline, intervention) }];


  var editedModes = modes.concat(newModes);
  return editedModes
}

function removeEndocut(modes) {
  modes.forEach((mode, index) => {
    if (mode.Mode === "endoCUT") {
      modes.splice(index, 1);
    }
  });
}

async function getVioCountForMode(mode, discipline, intervention) {
  var topVideoData = [];
  await Vue.axios.post('endocut/top', { "Discipline": discipline, "Intervention": intervention, "Mode": mode }).then(result => {
    topVideoData = result.data;

  }).catch(error => {
    throw new Error(`API ${error}`);
  });

  var topBarChartVideos = [];

  for (const videoData of topVideoData) {
    await Vue.axios.post('endocut/videos', { "Discipline": discipline, "Intervention": intervention, "Mode": mode, "Effect": videoData.E, "Duration": videoData.D, "Interval": videoData.I }).then(result => {
      topBarChartVideos = topBarChartVideos.concat(result.data)
    }).catch(error => {
      throw new Error(`API ${error}`);
    })
  }

  return topBarChartVideos.length;
}