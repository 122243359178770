<template>
  <div class="home-view">
    <Introduction />
    <GraphSection />
    <VideoUploadFormular />
    <ClinicalApplicationTeam />
    <MultimediaSection />
    <Footer />
  </div>
</template>

<script>
import Introduction from "@/sections/Introduction/IntroductionSection";
import GraphSection from "@/sections/Graph/GraphSection";
import ClinicalApplicationTeam from "@/sections/Team/ClinicalApplicationTeam";
import MultimediaSection from "@/sections/Multimedia/MultimediaSection";
import Footer from "@/sections/Footer/FooterSection";
import VideoUploadFormular from "@/sections/UploadFormular/VideoUploadFormular";

export default {
  name: "Home",
  components: {
    Introduction,
    GraphSection,
    VideoUploadFormular,
    ClinicalApplicationTeam,
    MultimediaSection,
    Footer,
  },
};
</script>
